import {
  Box,
  Button,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import axios from 'axios';
import React from 'react';
import { useQuery } from 'react-query';
import Header from '../../Components/layout/header/Header';
import { containerWidth } from '../../utils/dimentions';
import { getMealMapWithCategoryFromMealList } from './MeetingFoodUtils';
import classes from './MenuScreen.module.css';
import CategoryMenuContainer from './category_menu_list/CategoryMenuContainer';
import LocationMap from './location_map/LocationMap';
import MenuHeadCategory from './menu_head_category/MenuHeadCategory';
import { MealAction } from '../../store/meal-slice';
import { useDispatch, useSelector } from 'react-redux';
import { headers } from '../../utils/headerUtils';
import Footer from '../../Components/layout/footer/Footer';
import { useNavigate } from 'react-router-dom';
import PhoneMenuCategoryScreen from './phone_menu_category_screen/PhoneMenuCategoryScreen';
import { IndianCuisineMealAction } from '../../store/indian-cuisine-meal-slice';

const MenuScreen = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const cartItems = useSelector((state) => state.cart.items);
  const totalPrice = cartItems.reduce(
    (totalPrice, item) => totalPrice + item.totalPrice,
    0
  );
  const cartItemsCount = cartItems === undefined ? 0 : cartItems.length;

  const navigate = useNavigate();
  const item = {
    count: cartItemsCount,
    totalPrice: totalPrice,
  };

  const handleNavigationToCart = () => {
    navigate('/cart');
  };

  const dispatch = useDispatch();

  const {
    isLoading,
    isError,
    data: meals,
    error,
  } = useQuery(
    ['catering-meals'],
    async () => {
      const data = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_BASE_URL}/v1/public/catering-meals`,
        params: {
          language: 'English',
        },
        headers: headers,
      });
      return data.data;
    },
    { refetchOnWindowFocus: false }
  );

  let mealMap = {};
  if (meals !== undefined) {
    // mealMap['All'] = meals;

    // mealMap['Halal'] = meals
    //   .filter(
    //     (meal) =>
    //       meal?.tags?.includes('Halal') || meal?.tags?.includes('NW Halal')
    //   )
    // mealMap['Veg'] = meals
    //   .filter(
    //     (menu) => menu.meatType.toLowerCase() === 'NotApplicable'.toLowerCase()
    //   )
    mealMap = getMealMapWithCategoryFromMealList(meals);
    dispatch(IndianCuisineMealAction.addItemsToMenu(mealMap));
  }

  console.log('mealMap', mealMap);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: containerWidth,
        margin: 'auto',
      }}
    >
      <Header />
      <Divider />
      <LocationMap />
      <div className={classes.container}>
        {matches ? null : <MenuHeadCategory />}
        {/* For smaler screen use Phone layout for larger screen use large layout */}
        {matches ? <PhoneMenuCategoryScreen /> : <CategoryMenuContainer />}

        <div
          className={classes.cartInfoLayout}
          onClick={handleNavigationToCart}
        >
          <div className={classes.cartInfoItem}>
            <Typography variant='cartInfo'>{`${item.count} items`}</Typography>

            <Typography variant='cartInfo'>{`${item.totalPrice} NOK`}</Typography>
          </div>
          <div className={classes.cartInfoItem}>
            <Button
              variant='contained'
              color='secondary'
              onClick={handleNavigationToCart}
            >
              Proceed
            </Button>
          </div>
        </div>
      </div>
      <Footer />
    </Box>
  );
};

export default MenuScreen;
