import moment from 'moment';
import dayjsBusinessDays from 'dayjs-business-days';
import dayjs from 'dayjs';

dayjs.extend(dayjsBusinessDays);

export const getFormattedDate = (date) => {
  let year = date.getFullYear();
  let month = (1 + date.getMonth()).toString().padStart(2, '0');
  let day = date.getDate().toString().padStart(2, '0');

  return year + '-' + month + '-' + day;
};

export const dateFormatToDisplay = 'DD MMM YYYY';
export const dateFormatForServer = 'YYYY-MM-DD';

export const twoDaysFromCurrentDate = (date) => {
  console.log(
    'today:',
    dayjs(date).format(dateFormatToDisplay),
    '\nnext Day:',
    dayjs().businessDaysAdd(1).format(dateFormatToDisplay),
    '\nnext next Day:',
    dayjs().businessDaysAdd(2).format(dateFormatToDisplay)
  );

  return (
    date.date() === dayjs().date() ||
    date.date() === dayjs().businessDaysAdd(1).date() ||
    date.date() === dayjs().businessDaysAdd(2).date() ||
    date.day() === 6 ||
    date.day() === 0
  );
};
export const oneDayFromCurrentDate = (date) => {
  return (
    date.format(dateFormatToDisplay) === moment().format(dateFormatToDisplay) ||
    date.day() === 6 ||
    date.day() === 0
  );
};

export const nextBusinessDateFromCurrentDate = (date) => {
  const { nextBusinessDay } = getNextBusinessDay();

  return (
    date.format(dateFormatToDisplay) === moment().format(dateFormatToDisplay) ||
    date.format(dateFormatToDisplay) === nextBusinessDay ||
    date.day() === 6 ||
    date.day() === 0
  );
};

export const disableWeekEndDays = (date) => {
  return date.day() === 6 || date.day() === 0;
};

export const getNextBusinessDay = () => {
  const currentDay = moment().day();
  let nextBusinessDay = moment().add(1, 'day').format(dateFormatToDisplay);
  let nextNextBusinessDay = moment().add(2, 'day').format(dateFormatToDisplay);
  let nextBusinessDaySelection = moment()
    .add(3, 'day')
    .format(dateFormatToDisplay);
  switch (currentDay) {
    case 3:
      nextBusinessDaySelection = moment()
        .add(5, 'day')
        .format(dateFormatToDisplay);
      break;
    case 4: {
      nextNextBusinessDay = moment().add(4, 'day').format(dateFormatToDisplay);
      nextBusinessDaySelection = moment()
        .add(5, 'day')
        .format(dateFormatToDisplay);
      break;
    }
    case 5: {
      nextBusinessDay = moment().add(3, 'day').format(dateFormatToDisplay);
      nextNextBusinessDay = moment().add(4, 'day').format(dateFormatToDisplay);
      nextBusinessDaySelection = moment()
        .add(5, 'day')
        .format(dateFormatToDisplay);
      break;
    }
    case 6: {
      nextBusinessDay = moment().add(2, 'day').format(dateFormatToDisplay);
      nextNextBusinessDay = moment().add(3, 'day').format(dateFormatToDisplay);
      nextBusinessDaySelection = moment()
        .add(4, 'day')
        .format(dateFormatToDisplay);
      break;
    }
    default: {
    }
  }

  return { nextBusinessDay, nextNextBusinessDay, nextBusinessDaySelection };
};
