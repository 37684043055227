import React, { useEffect } from 'react';
import Partner from '../Components/Partner/Partner';
import Experience from '../Components/Experience/Experience';
import { Footer } from '../Components/Footer/Footer';
import { Navbar } from '../Components/Navbar/Navbar';
import { Slider } from '../Components/Slider/Slider';
import { About } from '../Components/About/About';
import { ButHow } from '../Components/ButHow/ButHow';
import { ExpMenu } from '../Components/ExpMenu/ExpMenu';
import { Sustain } from '../Components/Sustain/Sustain';
import { Together } from '../Components/Together/Together';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './Home.css';
import { Container } from '@mui/material';

function Home() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <Navbar />
      <Slider />
      <About />
      <ButHow />
      <ExpMenu />
      <Sustain />
      <Together />
      <Partner />
      <Experience />
      <Footer />
    </>
  );
}

export default Home;
