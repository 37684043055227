import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CreateMealAction } from '../../../../../store/create-meal-slice';

const meatTypesList = [
  'NotApplicable',
  'Fish',
  // 'Shellfish',
  'Pork',
  'Beef',
  'Mutton',
  'Venison',
  'Poultry',
];

const ProvideMeatTypeComponent = () => {
  const dispatch = useDispatch();
  const { meatType } = useSelector((state) => state.createMeal);
  return (
    <Grid2 container columnSpacing={1}>
      <Grid2 xs={2}>
        <Typography variant='s16_w600' component='span'>
          Provide a Meat Type:*
        </Typography>
      </Grid2>
      <Grid2 xs={5}>
        <FormControl>
          <RadioGroup
            aria-labelledby='demo-radio-buttons-group-label'
            defaultValue='grams'
            name='radio-buttons-group'
            row
            value={meatType}
            onChange={(e) => {
              dispatch(CreateMealAction.updateMeatType(e.target.value));
            }}
          >
            {meatTypesList?.map((item) => (
              <FormControlLabel
                key={item}
                sx={{ width: '30%' }}
                value={item}
                control={<Radio />}
                label={item}
              />
            ))}
          </RadioGroup>
        </FormControl>

        {/* {meatTypesList?.map((item) => (
          <FormControlLabel
            sx={{ width: '30%' }}
            value={meatTypes?.find((meatType) => item === meatType)}
            onChange={() => dispatch(CreateMealAction.updateMeatType(item))}
            control={<Checkbox />}
            label={item}
          />
        ))} */}
      </Grid2>
      {/* <Grid2 xs={2} direction='column' gap={3}>
        <Typography variant='s16_w500' sx={{ color: '#717074' }}>
          Red meat
        </Typography>
        <FormGroup>
          <FormControlLabel control={<Checkbox />} label='Beef' />
          <FormControlLabel control={<Checkbox />} label='Pork' />
          <FormControlLabel control={<Checkbox />} label='Goat' />
          <FormControlLabel control={<Checkbox />} label='Lamb' />
        </FormGroup>
      </Grid2>
      <Grid2 xs={2} direction='column' gap={3}>
        <Typography variant='s16_w500' sx={{ color: '#717074' }}>
          Poltry
        </Typography>
        <FormGroup>
          <FormControlLabel control={<Checkbox />} label='Chicken' />
          <FormControlLabel control={<Checkbox />} label='Turkey' />
          <FormControlLabel control={<Checkbox />} label='Some meat type' />
          <FormControlLabel control={<Checkbox />} label='Some meat type' />
        </FormGroup>
      </Grid2>
      <Grid2 xs={2} direction='column' gap={3}>
        <Typography variant='s16_w500' sx={{ color: '#717074' }}>
          Sea Food
        </Typography>
        <FormGroup>
          <FormControlLabel control={<Checkbox />} label='Crab' />
          <FormControlLabel control={<Checkbox />} label='Scallops' />
          <FormControlLabel control={<Checkbox />} label='Molluscs' />
          <FormControlLabel control={<Checkbox />} label='Mussels' />
        </FormGroup>
      </Grid2> */}
    </Grid2>
  );
};

export default ProvideMeatTypeComponent;
