import React from 'react';
import logo from './logo.svg';
import call from './Call.svg';
import './Navbar.css';
import { Button, Stack } from '@mui/material';
import toast from 'react-hot-toast';

export const Navbar = () => {
  const iosClick = () => {
    // ReactGA.event({
    //   category: 'Home',
    //   action: 'Click',
    //   label: 'Apple Store',
    // });
    window.open(
      'https://apps.apple.com/us/app/lunsj-p%C3%A5-d%C3%B8ra/id1617445017',
      '_blank'
    );
  };

  const andriodClick = () => {
    // ReactGA.event({
    //   category: 'Home',
    //   action: 'Click',
    //   label: 'Google Play Store',
    // });

    window.open(
      'https://play.google.com/store/apps/details?id=no.digiberry.lunchapp&gl=NO',
      '_blank'
    );
  };

  const handleOnClickLogin = () => {
    window.open('https://admin.foodfe.app', '_blank');
  };

  return (
    <nav className='navbar navbar-expand-lg bg-body-tertiary'>
      <div className='container-fluid'>
        <a className='navbar-brand ms-3' href='#'>
          <img src={logo} alt='Bootstrap' width='180' height='79'></img>
        </a>
        <button
          className='navbar-toggler'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#navbarSupportedContent'
          aria-controls='navbarSupportedContent'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <span className='navbar-toggler-icon'></span>
        </button>
        <div className='collapse navbar-collapse' id='navbarSupportedContent'>
          <ul className='navbar-nav mx-auto mb-2 mb-lg-0'>
            <li className='nav-item px-2 ms-0 ms-md-5'>
              <a className='nav-link' href='#about'>
                About
              </a>
            </li>
            <li className='nav-item px-2'>
              <a className='nav-link' href='#ButHow'>
                How It Works
              </a>
            </li>

            <li className='nav-item px-2'>
              <a className='nav-link' href='#expmenu'>
                Menu
              </a>
            </li>

            <li className='nav-item px-2'>
              <a className='nav-link' href='#sustain'>
                Sustainability
              </a>
            </li>
          </ul>
          <ul className='navbar-nav me-1'>
            <li className='nav-item me-3 '>
              <Stack className='pt-2'>
                <Button
                  variant='contained'
                  sx={{
                    alignSelf: 'end',
                    width: {
                      sm: '30%',
                      md: '30%',
                      lg: '100%',
                    },
                  }}
                  onClick={handleOnClickLogin}
                >
                  Login
                </Button>
                <a className='nav-link mt-2' href='tel:+4741350051'>
                  <img src={call} /> +4741350051
                </a>
                <Stack direction='row' columnGap={1} mt={1} alignSelf='end'>
                  <a href='#' onClick={andriodClick} rel='noreferrer noopener'>
                    <img
                      src='assets/images/banners/gp.webp'
                      alt='image'
                      style={{ height: 30 }}
                    />
                  </a>
                  <a href='#' onClick={iosClick} rel='noreferrer noopener'>
                    <img
                      src='assets/images/banners/as.webp'
                      alt='image'
                      style={{ height: 30 }}
                    />
                  </a>
                </Stack>
              </Stack>
            </li>
            {/* 		<li className="nav-item me-3">
							<button className="btn btn-primary" type="submit">
								Order Now
							</button>
						</li> */}
          </ul>
          <ul className='nav navbar-nav visible-xs'>
            <li className='pull-right'>
              <a href='tel:phone-number'>
                <i className='glyphicon glyphicon-phone'></i>
              </a>
            </li>
            <li className='pull-right'>
              <a href='#'>
                <i className='glyphicon glyphicon-map-marker'></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
