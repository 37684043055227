import {
  Autocomplete,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ScheduleMealAction } from '../../../../store/schedule-meal-slice';
import {
  dateFormatForServer,
  twoDaysFromCurrentDate,
} from '../../../../utils/dateUtils';
import { queryKeys } from '../../../../utils/queryKeys';
import { createSchedule } from '../ScheduleApi';
import MealPlanItem from './MealPlanItem';
import SelectMealPlanItem from './SelectMealPlanItem';

const CreateScheduleItem = ({ meals, mealsPlan }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [meal, setMeal] = useState(null);
  const queryClient = useQueryClient();
  console.log('Filtered Meals', meals);

  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.globalUi);
  const {
    showAddMore,
    selectedBoxType,
    selection,
    mealBoxTypes,
    boxType,
    date,
  } = useSelector((state) => state.scheduleMeal);

  console.log('formattedDate', date);

  let toastId;

  useEffect(() => {
    const saveMealPlan = () => {
      if (mealsPlan !== undefined && mealsPlan !== null) {
        if (mealsPlan?.Lunch && mealsPlan?.Lunch?.length > 0) {
          dispatch(
            ScheduleMealAction.updateBoxType({
              boxType: 'Lunch',
              mealBoxType: mealsPlan.Lunch,
            })
          );
        } else {
          dispatch(
            ScheduleMealAction.updateBoxType({
              boxType: 'Dinner',
              mealBoxType: mealsPlan.Dinner,
            })
          );
        }
        // dispatch(ScheduleMealAction.setMealBoxTypes(mealsPlan));
        //If this is edit mode then set boxtype according to selection.
        if (location?.state?.boxType)
          dispatch(
            ScheduleMealAction.updateBoxType({
              boxType: location.state.boxType,
              mealBoxType: mealsPlan[location?.state?.boxType],
            })
          );
      }
    };
    saveMealPlan();
  }, [mealsPlan, dispatch]);

  const defaultProps = {
    options: meals,
    getOptionLabel: (option) =>
      language === 'English' ? option.details[0].name : option.details[1].name,
  };

  const handleBoxTypeChange = (event) => {
    dispatch(
      ScheduleMealAction.updateBoxType({
        boxType: event.target.value,
        mealBoxType: mealsPlan[event.target.value],
      })
    );
  };

  const handleAddThisFoodBoxClick = () => {
    if (meal == null || meal === undefined) {
      toast.error('Please select a meal box');
      return;
    }
    const scheduleData = {
      date: date,
      scheduleType: boxType,
      mealId: meal.externalId,
    };

    if (!showAddMore && mealBoxTypes?.length !== 0) {
      toast.error('Please save your meal plan');
      return;
    }
    if ((selectedBoxType?.length ?? 0) === 0) {
      toast.error('Please select a meal plan');
      return;
    } else {
      const data = selectedBoxType.map((item) => {
        return {
          ...scheduleData,
          mealPlanId: item.id,
        };
      });
      toastId = toast.loading('Processing...');
      createNewSchedule({ data });
    }
  };

  const handleAddMoreClicked = () => {
    dispatch(ScheduleMealAction.addMoreItem());
  };

  const handleDiscardClick = () => {
    dispatch(ScheduleMealAction.reset());
    navigate(-1);
  };

  const handleErr = async (err) => {
    console.log('handleErr');
    console.dir(err);
    toast.dismiss(toastId);
    try {
      toast.error(err.response.data.message);
    } catch (err) {
      toast.error('Something went wrong!');
    }
  };

  const handleSuccessResponse = async (data) => {
    toast.dismiss(toastId);
    toast.success('Meal schedule created successfully');
    //Reset UI
    dispatch(
      ScheduleMealAction.updateBoxType({
        boxType: boxType,
        mealBoxType: mealsPlan[boxType],
      })
    );

    queryClient.invalidateQueries({ queryKey: [queryKeys.getAllScheduleData] });
  };

  const { mutate: createNewSchedule } = useMutation(
    ({ data }) => createSchedule(data),
    {
      onSuccess: handleSuccessResponse,
      onError: handleErr,
    }
  );

  console.log('Selected meal');
  console.dir(meal);

  return (
    <Stack rowGap={3} mb={8}>
      <Stack container border='1px solid #BDBDBD' direction='row'>
        <Stack borderRight='1px solid #BDBDBD' p={3}>
          <Typography variant='s16_w500' sx={{ alignSelf: 'center' }}>
            Select a date and create a schedule
          </Typography>
          <hr />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
              disablePast
              shouldDisableDate={twoDaysFromCurrentDate}
              maxDate={dayjs().add(2, 'months')}
              value={dayjs(date)}
              onChange={(newValue) =>
                dispatch(
                  ScheduleMealAction.updateSelectedDate(
                    newValue.format(dateFormatForServer)
                  )
                )
              }
              // shouldDisableDate={disableWeekends}
            />
          </LocalizationProvider>
        </Stack>
        <Stack p={3} rowGap={3} sx={{ width: '80%' }}>
          <Stack direction='row'>
            <Typography variant='s20_w500' component='span'>
              You are now creating a schedule for:
            </Typography>
            &nbsp;
            <Typography variant='s20_w700' component='span'>
              {dayjs(date).format('DD/MM/YYYY')}
            </Typography>
          </Stack>
          <Grid2 container direction='row' alignItems='center'>
            <Grid2 xs={3}>
              <Typography variant='s16_w600' component='span'>
                Select a meal Box:
              </Typography>
            </Grid2>
            <Grid2 xs={4}>
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  id='combo-box-demo'
                  {...defaultProps}
                  value={meal}
                  onChange={(event, newValue) => {
                    setMeal(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label='Meal Box' />
                  )}
                />
              </FormControl>
            </Grid2>
          </Grid2>
          <Grid2 container rowGap={3}>
            <Grid2 xs={3}>
              <Typography variant='s16_w600' component='span'>
                Choose Box Type:
              </Typography>
            </Grid2>
            <Grid2 xs={6}>
              <Stack direction='row' alignItems='center'>
                <FormControl>
                  <RadioGroup
                    aria-labelledby='demo-radio-buttons-group-label'
                    name='radio-buttons-group'
                    row
                    value={boxType}
                    onChange={handleBoxTypeChange}
                  >
                    <FormControlLabel
                      value='Lunch'
                      disabled={
                        !(mealsPlan?.Lunch && mealsPlan?.Lunch?.length > 0)
                      }
                      control={<Radio />}
                      label='Lunch'
                    />
                    <FormControlLabel
                      value='Dinner'
                      disabled={
                        !(mealsPlan?.Dinner && mealsPlan?.Dinner?.length > 0)
                      }
                      control={<Radio />}
                      label='Dinner'
                    />
                  </RadioGroup>
                </FormControl>
                {/* <Stack
                  ml={2}
                  columnGap={1}
                  direction='row'
                  alignItems='center'
                  sx={{
                    ':hover': {
                      cursor: 'pointer',
                    },
                  }}
                >
                  <ErrorOutlineIcon style={{ color: '#F5825E' }} />
                  <Typography variant='s16_w500' color='#F5825E'>
                    Learn more
                  </Typography>
                </Stack> */}
              </Stack>
            </Grid2>
            <Grid2 xs={3} />
          </Grid2>
          {selectedBoxType.map((item) => (
            <MealPlanItem mealType={item} mealBoxTypes={mealBoxTypes} />
          ))}
          {!showAddMore && mealBoxTypes?.length !== 0 ? (
            <SelectMealPlanItem
              selection={selection}
              mealBoxTypes={mealBoxTypes}
            />
          ) : null}
          {showAddMore ? (
            <Button
              onClick={handleAddMoreClicked}
              variant='outlined'
              sx={{ alignSelf: 'start' }}
            >
              Add more
            </Button>
          ) : null}
        </Stack>
      </Stack>
      <Stack direction='row' columnGap={2} justifyContent='end'>
        <Button variant='contained' onClick={handleAddThisFoodBoxClick}>
          Add this Food Box
        </Button>
        <Button variant='outlined' onClick={handleDiscardClick}>
          Discard
        </Button>
      </Stack>
    </Stack>
  );
};

export default CreateScheduleItem;
