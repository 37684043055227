import {
  Box,
  Button,
  Menu,
  MenuItem,
  ThemeProvider,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';
import {
  axiosInstance,
  setupLogoutMechanism,
} from '../../../Screens/menu_planner/AxiosInstance';
import { menuPlannerTheme } from '../../../utils/MenuPlannerTheme';
import classes from './PlannerHeader.module.css';
import LanguageSwitch from '../../../Screens/menu_planner/components/LanguageSwitch';
import { useDispatch, useSelector } from 'react-redux';
import { AuthActions } from '../../../store/auth-slice';
import ProfileMenu from './ProfileMenu';
import CalendarMenu from './CalendarMenu';
import { testMealPlan } from '../../../utils/dummyData';
const PlannerHeader = () => {
  const navigate = useNavigate();
  const navStyle = ({ isActive }) =>
    isActive ? classes.navActive : classes.navStyle;
  const { user } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  setupLogoutMechanism(navigate, dispatch);

  return (
    <ThemeProvider theme={menuPlannerTheme}>
      <Box>
        <div
          className={`${classes.headerContainer} ${classes.headerContainerBgColor}`}
        >
          <div className={classes.headerLogoAndTitle}>
            <img
              className='logo-img'
              src={process.env.PUBLIC_URL + '/assets/logo.svg'}
              alt='logo'
            />
            <Typography
              component='span'
              variant='s18_w600'
              className={classes.title}
              lineHeight='300%'
            >
              Menu Planner
            </Typography>
          </div>

          {token !== null ? (
            <div className={classes.navHolder}>
              {/* <NavLink to='dashboard' className={navStyle}>
                <Typography variant='s16_w600'>Dashboard</Typography>
              </NavLink> */}

              <NavLink to='food-boxes' className={navStyle}>
                <Typography variant='s16_w600'>Food Boxes</Typography>
              </NavLink>

              <NavLink to='schedules' className={navStyle}>
                <Typography variant='s16_w600'>Schedules</Typography>
              </NavLink>

              <CalendarMenu mealsPlan={user?.canteen?.mealPlans} />
              <LanguageSwitch />
              <NavLink to='notification' className={navStyle}>
                <img
                  src={
                    process.env.PUBLIC_URL +
                    '/assets/menu_planner/notification-bell.svg'
                  }
                  alt='Notification'
                  className={classes.headerIcon}
                />
              </NavLink>
              <ProfileMenu />
            </div>
          ) : null}
        </div>
        <Box className={classes.childContainer}>
          <Outlet />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default PlannerHeader;
